
import { defineComponent, nextTick, reactive, ref } from 'vue'
import SwiperCore, { Autoplay, Pagination } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import AMapLoader from '@amap/amap-jsapi-loader'
import 'swiper/swiper.less'
import 'swiper/components/pagination/pagination.less'

SwiperCore.use([Autoplay, Pagination])

export default defineComponent({
  components: { Swiper, SwiperSlide },
  setup() {
    // 联系我们地图渲染
    const map$ = ref<HTMLDivElement>()
    let map = null
    const loadMap = () => {
      return AMapLoader.load({
        key: '28c47d76a063178a3c938af0be1b1a9c',
        version: ''
      })
    }
    nextTick(async () => {
      const AMap = await loadMap()
      map = new AMap.Map(map$.value, {
        zoom: 15,
        center: [113.044063, 28.235125]
      })
      const marker = new AMap.Marker({
        position: new AMap.LngLat(113.044063, 28.235125)
      })
      map.add(marker)
    })

    const brandList = reactive([
      'https://ecompic1.hitv.com/ecom/1/c87c6f5b92ab160762e5c9a97e2e58cd.png',
      'https://ecompic0.hitv.com/ecom/1/46231570b31dfdd535df56270120f586.png',
      'https://ecompic3.hitv.com/ecom/1/a8da57b8d81c81ae58a3910ce15e488e.png',
      'https://ecompic3.hitv.com/ecom/1/6d387804c4da95883e54c841a75b71e3.png',
      'https://ecompic3.hitv.com/ecom/1/0cd711d733399a2fe6d607ae0e206916.png',
      'https://ecompic2.hitv.com/ecom/1/29ce95e266178777645c88b5b5bbafc7.png',
      'https://ecompic0.hitv.com/ecom/1/f9b3471b8d1fcf96c067c3b4290e339b.png',
      'https://ecompic0.hitv.com/ecom/1/6e0ae5ee2ef318803b3e774a82772ac8.png',
      'https://ecompic2.hitv.com/ecom/1/294879838ee2d48d33a85f73f1cfd285.png',
      'https://ecompic2.hitv.com/ecom/1/a7af51704d8154ba546263b23575b367.png',
      'https://ecompic2.hitv.com/ecom/1/07c96b1d41bf3a69fedcabff9a339638.png',
      'https://ecompic2.hitv.com/ecom/1/48a82c969c2a72d958ece6f3c854bf93.png',
      'https://ecompic3.hitv.com/ecom/1/2867ae726f5690e97d016337fd0ffa6b.png',
      'https://ecompic2.hitv.com/ecom/1/544c52536eed663f30fc41c82caecd6e.png',
      'https://ecompic3.hitv.com/ecom/1/03c151962668f7710996947b74fb53a1.png',
      'https://ecompic1.hitv.com/ecom/1/ce59e058bb643c62ca77a7cfca5e1c88.png',
      'https://ecompic1.hitv.com/ecom/1/e7fb59dc2f83cb23a99450f31a4e4364.png',
      'https://ecompic0.hitv.com/ecom/1/6e1ca0474308356119a7e810faba307e.png',
      'https://ecompic3.hitv.com/ecom/1/dacbc6bfec09ffced21e086b52b64c3d.png',
      'https://ecompic1.hitv.com/ecom/1/9611ea8127825ea8ca32dff0239c3c8a.png',
      'https://ecompic0.hitv.com/ecom/1/c1ca7f26f6fbfaa3a1f64a96972e9e7d.png',
      'https://ecompic2.hitv.com/ecom/1/9a8ce1c7af50dda784185439434ae96e.png',
      'https://ecompic2.hitv.com/ecom/1/9bc4412b44bf4b68feb324eae94b7dde.png',
      'https://ecompic2.hitv.com/ecom/1/d094d1384fbdded331cb19dbe9e7d58c.png',
      'https://ecompic3.hitv.com/ecom/1/3c0b4fd6a9f4f47ba6f8bd71ef09bf64.png',
      'https://ecompic0.hitv.com/ecom/1/4c3586c3b227960b0dc3b93a6a61dfef.png',
      'https://ecompic3.hitv.com/ecom/1/f3e332fee32fb33e5d3dae9dab498528.png',
      'https://ecompic0.hitv.com/ecom/1/921ba524d8e8bbca79dadb050afb7524.png',
      'https://ecompic1.hitv.com/ecom/1/4a5da7791deb17d284755651b5963cc8.png',
      'https://ecompic3.hitv.com/ecom/1/898d26829e1484dac63ca8f1afa04ba4.png'
    ])
    return {
      map$,
      brandList
    }
  }
})
